import styled from 'styled-components';
import { breakpoint } from 'theme';

import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled.div`
  display: flex;
  max-width: ${({ theme }) => theme.wrapperXxl};
  display: block;
  margin-inline: auto;

  ul,
  ol {
    padding-left: 20px;
    margin-block: ${({ theme }) => theme.gap.md};
  }

  ol {
    list-style: decimal;

    li::marker {
      color: ${({ theme }) => theme.colors.primary50};
    }
  }

  ul {
    list-style: none;
    li::before {
      content: '• ';
      color: ${({ theme }) => theme.colors.primary50};
    }
  }

  [data-variant='body1'] {
    a {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      background-color: ${({ theme }) => theme.colors.transparent};
      color: ${({ theme }) => theme.colors.primary50};
      gap: ${({ theme }) => theme.gap.xs};
      text-decoration: none;
      background-image: linear-gradient(
        ${({ theme }) => theme.colors.primary50},
        ${({ theme }) => theme.colors.primary50}
      );
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 0% 0.07em;
      transition: background-size 0.7s;

      &:disabled {
        color: ${({ theme }) => theme.colors.gray60};
      }

      &:hover {
        background-size: 100% 0.07em;
      }
    }
  }
  p {
    strong {
      font-weight: 700;
      text-decoration: unset;
    }
  }

  p,
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: ${({ theme }) => theme.gap.md};
  }

  table {
    margin-block: ${({ theme }) => theme.gap.md};
    width: 100%;
    display: block;
    max-width: 600px;
    overflow-x: auto;
    white-space: nowrap;

    tr {
      display: flex;
      flex-wrap: nowrap;

      td,
      th {
        max-width: 300px;
        white-space: normal;
      }
    }

    tr {
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray10};
    }

    th,
    td {
      padding: 10px;
      min-width: 120px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  td {
    strong {
      font-weight: bold;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }
  ${breakpoint.md} {
    table {
      width: 100%;
      border-collapse: collapse;
      margin: 20px 0;

      max-width: unset;
      white-space: unset;
      -webkit-overflow-scrolling: touch;
      tr {
        display: table-row;
        vertical-align: inherit;

        td,
        th {
          max-width: unset;
          white-space: unset;
        }
      }
    }
  }
`;

export const StyledText = styled(Typography)`
  font-weight: bold;
  margin-inline: auto;
  max-width: ${({ theme }) => theme.wrapper};
  padding: ${({ theme }) => theme.gap.mdl} ${({ theme }) => theme.gap.sm};
  h2 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 16px;

    ${breakpoint.md} {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &[data-variant='body1'] {
    margin-bottom: 16px;
    font-size: 16px;
  }

  ${breakpoint.md} {
    padding: ${({ theme }) => theme.gap.xl} 0;
  }
`;
