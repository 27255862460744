import React, { ReactElement } from 'react';
import { DefaultType } from 'types/default';

import Typography from 'components/atoms/typography';

import { useScreenService } from 'hooks/use-screen-service';

import {
  StyledContainerBreadcrumb,
  StyledContainerBreadcrumbBig,
  StyledContainerTitle,
  StyledDotsGridTwo,
  StyledTitle,
  StyledWrapper,
} from './head-title-description.styles';

export type HeadTitleDescriptionType = {
  children?: ReactElement;
  containerWidth?: containerMaxWidthType;
  showDots: boolean;
  isWhite?: boolean;
  isBig?: boolean;
} & Record<'titleTop' | 'subtitleTop', string> &
  DefaultType;
export type containerMaxWidthType = 'small' | 'default' | 'full';

const HeadTitleDescription = ({
  titleTop,
  subtitleTop,
  children,
  showDots,
  isWhite,
  padding,
  isBig = false,
  containerWidth = 'default',
}: HeadTitleDescriptionType) => {
  const { isMobile } = useScreenService();
  return (
    <div>
      <StyledWrapper isWhite={isWhite} padding={padding}>
        {isBig ? (
          <StyledContainerBreadcrumbBig>{children}</StyledContainerBreadcrumbBig>
        ) : (
          <StyledContainerBreadcrumb isWhite={isWhite}>{children}</StyledContainerBreadcrumb>
        )}
        <StyledContainerTitle data-variant={containerWidth}>
          <StyledTitle
            color={isWhite ? 'gray100' : 'white'}
            component="h1"
            variant="title1"
            html={titleTop}
          />
          {subtitleTop ? (
            <Typography
              color={isWhite ? 'gray60' : 'whiteAlpha72'}
              variant="title2"
              component="h2"
              html={subtitleTop}
            />
          ) : null}
        </StyledContainerTitle>
        {showDots ? (
          <StyledDotsGridTwo
            width={isMobile ? 0 : 2}
            height={isMobile ? 0 : 7}
            color={isWhite ? 'complementary3' : 'complementary2'}
            isWhite={isWhite}
          />
        ) : null}
      </StyledWrapper>
    </div>
  );
};

export default HeadTitleDescription;
