import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import HeadTitleDescription from 'components/organisms/head-title-description';
import PrivacyDescriptionText from 'components/molecules/privacy-description-text';
import Breadcrumb from 'components/atoms/breadcrumb';
import RichText from 'components/atoms/rich-text';

type DataProps = {
  privacyPolicyPage: {
    template: {
      privacyPolicyData: {
        bannerSimple: bannerSimpleType;
        contentText: string;
      };
    };
  };
};
export type bannerSimpleType = Record<'heading' | 'text', string>;
const PrivacyPolicyPage = ({
  pageContext,
  data: {
    privacyPolicyPage: {
      template: {
        privacyPolicyData: {
          bannerSimple: { text, heading },
          contentText,
        },
      },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeadTitleDescription
      containerWidth="full"
      showDots={false}
      titleTop={heading}
      subtitleTop={text}
    >
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescription>
    <PrivacyDescriptionText>
      <RichText html={contentText} />
    </PrivacyDescriptionText>
  </Layout>
);
export const query = graphql`
  query ($id: String) {
    privacyPolicyPage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_PrivacyPolicy {
          privacyPolicyData {
            bannerSimple {
              heading
              text
            }
            contentText
          }
        }
      }
    }
  }
`;

export default PrivacyPolicyPage;
