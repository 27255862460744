import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import DotsGrid from 'components/molecules/dots-grid';
import Container from 'components/atoms/container';
import Typography from 'components/atoms/typography';

const defaultWidth = '1100px';
const dotPositionLeft = '42px';
const dotPositionRight = '50px';

type StyledWrapperType = { isWhite?: boolean } & DefaultType;

export const StyledWrapper = styled.div<StyledWrapperType>`
  position: relative;
  padding-bottom: ${({ isWhite, theme }) => (isWhite ? `${theme.gap.xl}` : 0)};
  padding: ${({ padding }) => padding};
  background: ${({ isWhite, theme }) =>
    isWhite ? `${theme.colors.transparent}` : `${theme.colors.complementary1}`};
`;

export const StyledContainerBreadcrumbBig = styled(Container)<{ isWhite?: boolean }>`
  padding-top: ${({ theme }) => theme.gap.md};
  max-width: ${({ theme }) => theme.wrapper};

  & .breadcrumb__separator {
    color: ${({ isWhite, theme }) =>
      isWhite ? `${theme.colors.gray40}` : `${theme.colors.whiteAlpha40}`};
  }

  & .breadcrumb__list__item a {
    color: ${({ isWhite, theme }) =>
      isWhite ? `${theme.colors.gray100}` : `${theme.colors.white}`};
  }

  & .breadcrumb__list__item a[aria-current='page'] {
    color: ${({ isWhite, theme }) =>
      isWhite ? `${theme.colors.gray60}` : `${theme.colors.whiteAlpha40}`};
  }
`;

export const StyledContainerBreadcrumb = styled(Container)<{ isWhite?: boolean }>`
  padding-top: ${({ theme }) => theme.gap.md};
  max-width: ${({ isWhite, theme }) => (isWhite ? `${theme.wrapper}` : defaultWidth)};

  & .breadcrumb__separator {
    color: ${({ isWhite, theme }) =>
      isWhite ? `${theme.colors.gray40}` : `${theme.colors.whiteAlpha40}`};
  }

  & .breadcrumb__list__item a {
    color: ${({ isWhite, theme }) =>
      isWhite ? `${theme.colors.gray100}` : `${theme.colors.white}`};
  }

  & .breadcrumb__list__item a[aria-current='page'] {
    color: ${({ isWhite, theme }) =>
      isWhite ? `${theme.colors.gray60}` : `${theme.colors.whiteAlpha40}`};
  }
`;

export const StyledContainerTitle = styled(Container)`
  padding-block: ${({ theme }) => theme.gap.md};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.xl};
  }

  &[data-variant='small'] {
    max-width: 726px;
  }

  &[data-variant='default'] {
    max-width: 918px;
  }

  &[data-variant='full'] {
    max-width: ${({ theme }) => theme.wrapper};
  }
`;

export const StyledDotsGridTwo = styled(DotsGrid)<{ isWhite?: boolean }>`
  display: none;
  z-index: 1;
  top: 46%;

  ${breakpoint.lg} {
    display: block;
    left: 20px;
    top: 46%;
  }

  ${breakpoint.xl} {
    left: ${({ isWhite }) => (isWhite ? 'auto' : dotPositionLeft)};
    right: ${({ isWhite }) => (isWhite ? dotPositionRight : 'auto')};
  }
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.gap.sm};

  ${breakpoint.lg} {
    margin-bottom: ${({ theme }) => theme.gap.md};
  }
`;
