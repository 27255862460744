import React, { ReactElement } from 'react';

import { StyledContainer, StyledText, StyledWrapper } from './privacy-description-text.styles';

export type PrivacyDescriptionTextType = {
  children?: ReactElement;
};

const PrivacyDescriptionText = ({ children }: PrivacyDescriptionTextType) => (
  <StyledWrapper>
    <StyledContainer>
      <StyledText variant="body1" color="gray100">
        {children}
      </StyledText>
    </StyledContainer>
  </StyledWrapper>
);

export default PrivacyDescriptionText;
